import { EventEmitter } from '@angular/core';
import { Component, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { FrameWorkService } from 'src/app/shared/frame-work.service';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm: any;
  constructor(private router : Router, 
    private httpService: FrameWorkService,
    private spinner: NgxSpinnerService) { }

  ngOnInit() {
  }

  login(loginform: any)  {
    console.log(loginform.value.email);
    console.log('request for login')
    this.httpService.authenticate(loginform.value.email , loginform.value.pwd)
    // if(resp.isSuccess) {
    //   this.router.navigateByUrl('dashboard');
    //   this.httpService.openSnankBar(resp.message);
    //   setTimeout(() => {
    //     this.httpService.closeSnankBar();
    //   }, 1000);
    // } else if(!resp.isSuccess) {
    //   this.httpService.openSnankBar(resp.message);
    //   setTimeout(() => {
    //     this.httpService.closeSnankBar();
    //   }, 2000)
    // }
  }

  
}
