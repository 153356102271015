import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { Router, RouterModule, Routes } from '@angular/router';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LeftNavComponent } from './component/left-nav/left-nav.component';
import { DashboardComponent } from './component/dashboard-component/dashboard-component.component';
import { HashLocationStrategy, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonHeaderComponent } from './component/common-header/common-header.component';
import { GridToolComponent } from './component/grid-tool/grid-tool.component';
import { DatatableComponent } from './component/datatable/datatable.component';
import { LoginComponent } from './component/login/login.component';
import { FormGroup, FormControl, Validators, FormBuilder }  from '@angular/forms';
import { FormContainerComponent } from './component/form-container/form-container.component';
import { WidgetComponent } from './component/elements/widget/widget.component';
import { AuthGuard } from './shared/auth.guard';
import { HttpInterceptorService } from './shared/http.interceptor';
import { AngularFireModule } from "@angular/fire";
import { environment } from '../environments/environment'
import {MatSelectModule} from '@angular/material/select';
import { AngularFirestoreModule } from 'angularfire2/firestore';


// Module
import {MatTableModule} from '@angular/material/table';
import {MatButtonModule} from '@angular/material/button';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatCardModule} from '@angular/material/card';
import { FormsModule } from '@angular/forms';
import {ReactiveFormsModule} from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgxSpinnerModule } from "ngx-spinner";
import {MatSnackBarModule, MAT_SNACK_BAR_DEFAULT_OPTIONS} from '@angular/material/snack-bar';
import {MatPaginatorModule} from '@angular/material/paginator';
import { AngularFireAuthModule } from 'angularfire2/auth';
import { CategoryComponent } from './component/category/category.component';
import { LanguageMappingComponent } from './component/language-mapping/language-mapping.component';
import { CalenderComponent } from './component/calender/calender.component';
import { SubCategoryComponent } from './component/sub-category/sub-category.component';
import {AngularFireStorageModule } from 'angularfire2/storage';
import { MetaDataComponent } from './meta-data/meta-data.component';
import { HomeComponent } from './home/home.component'




const routes:Routes = [
  { path: '' , redirectTo: 'home', pathMatch:'full'  },
  { path: 'home' , component: HomeComponent  ,  pathMatch: 'full'},
  { path: 'dg-login', component: LoginComponent },
  { path: 'sub_category', component: SubCategoryComponent , canActivate: [AuthGuard] },
  { path: 'calender', component: CalenderComponent , canActivate: [AuthGuard] },
  { path: 'category', component: CategoryComponent , canActivate: [AuthGuard] },
  { path: 'metadata', component: MetaDataComponent , canActivate: [AuthGuard] },
  { path: 'lov', component: LanguageMappingComponent , canActivate: [AuthGuard] }

]
@NgModule({
  declarations: [
    AppComponent,
    LeftNavComponent,
    DashboardComponent,
    CommonHeaderComponent,
    GridToolComponent,
    DatatableComponent,
    LoginComponent,
    FormContainerComponent,
    WidgetComponent,
    CategoryComponent,
    LanguageMappingComponent,
    CalenderComponent,
    SubCategoryComponent,
    MetaDataComponent,
    HomeComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    RouterModule.forRoot(routes),
    BrowserAnimationsModule,
    FormsModule,
    MatTableModule,
    MatButtonModule,
    MatFormFieldModule,
    AngularFireStorageModule,
    MatCardModule,
    ReactiveFormsModule,
    AngularFirestoreModule,
    MatInputModule,
    HttpClientModule,
    NgxSpinnerModule,
    MatSnackBarModule,
    MatSelectModule,
    MatPaginatorModule,
    AngularFireModule.initializeApp({
      apiKey: "AIzaSyAhzA7rDSbsz8ikKGpM_CKqWgvdns_vSpw",
      authDomain: "digitalgujarati-c7679.firebaseapp.com",
      databaseURL: "https://digitalgujarati-c7679-default-rtdb.firebaseio.com",
      projectId: "digitalgujarati-c7679",
      storageBucket: "digitalgujarati-c7679.appspot.com",
      messagingSenderId: "356331632818",
      appId: "1:356331632818:web:b79b61d26ac7a47a2471ab",
      measurementId: "G-9LFD9M92WF"
    }),
    AngularFireAuthModule
    
  ],
  providers: [{ provide: LocationStrategy, useClass: HashLocationStrategy }, 
    {provide: HTTP_INTERCEPTORS, useClass: HttpInterceptorService, multi: true }, 
    {provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: {duration: 1000}}],
  bootstrap: [AppComponent]
})
export class AppModule { }
