import { CurrencyPipe, DecimalPipe, PercentPipe } from '@angular/common';
import { ChangeDetectorRef, Component, ElementRef, OnInit, TemplateRef, VERSION, ViewChild, ViewContainerRef } from '@angular/core';
import { FormBuilder, FormGroup, NgForm } from '@angular/forms';
import { Sort } from '@angular/material/sort';
import { AngularFireStorage  } from 'angularfire2/storage';
import * as moment from 'moment';
import { finalize } from 'rxjs/operators';
import { FrameWorkService } from 'src/app/shared/frame-work.service';


@Component({
  selector: 'app-sub-category',
  templateUrl: './sub-category.component.html',
  styleUrls: ['./sub-category.component.scss']
})
export class SubCategoryComponent implements OnInit {
  @ViewChild('gridContainer', { read: ViewContainerRef, static: true }) gridContainerRef: ViewContainerRef | undefined;
  @ViewChild('gridTemplate', { static: true }) gridTemplateRef: TemplateRef<any> | any;
  @ViewChild('formContainer', { read: ViewContainerRef, static: true }) formContainerRef: ViewContainerRef | undefined;
  @ViewChild('formTemplate', { static: true }) formTemplateRef: TemplateRef<any> | any;
  @ViewChild('toolbarContainer', { read: ViewContainerRef, static: true }) toolbarContainerRef: ViewContainerRef | undefined;
  @ViewChild('toolbarTemplate', { static: true }) toolbarTemplateRef: TemplateRef<any> | any;
  data: { title: string; link: string; cssClass: string; }[] | any;
  pageTitle: string = "SubCategory Management";
  subCategoryForm: NgForm | undefined;
  authorsData = [];
  status: boolean = true;
  calenderColumns = [{ title: 'Category Name', key: 'categoryName', type: 'string' },
  { title: 'Subcategory', key: 'subCategoryName', type: 'string' },
  { title: 'Translation keyword', key: 'translationKeyword', type: 'string' },
  // { title: 'Description', key: 'description', type: 'string' },
  // { title: 'Image', key: 'subCategoryImageUrl', type: 'img' },
  // { title: 'Last Modified', key: 'lastUpdate', type: 'string' },
  { title: 'Status', key: 'status', type: 'string' }];
  totalRecords: number | undefined;
  perPage: number = 5;
  subCategoryList = [];
  categoryName: string = "";
  subCategoryName: string = ""
  preferenceOrder: string = ""
  isUpdate: boolean | undefined = false;
  recordId: any;
  version = VERSION;
  base64File: any
  filename: any;
  fileToUpload: FileList | null | undefined;
  task: any;
  ref: any;
  uploadProgress: any;
  downloadURL: any;
  downloadUrl$: any;
  // subCategoryImageUrl:any[] = [];
  categoryDataList = [];
  description: string= "";
  translationKeyword: string= "";
  imageArr: any[] = [];

  constructor(private fb: FormBuilder,
    private storage: AngularFireStorage,
    private httpService: FrameWorkService,
    private cdRef: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.setHeaderConfig();
    this.loadGridModule();
    this.getAllCategory();
    this.loadToolBarModule();
    this.getListOfSubCategory();
  }

  loadGridModule() {
    this.gridContainerRef?.createEmbeddedView(this.gridTemplateRef);
  }

  loadFromModule() {
    this.formContainerRef?.createEmbeddedView(this.formTemplateRef);
  }

  loadToolBarModule() {
    this.toolbarContainerRef?.createEmbeddedView(this.toolbarTemplateRef);
  }

  setHeaderConfig() {
    this.data = []
  }

  createForm() {
    this.categoryName = "";
    this.recordId = "";
    this.subCategoryName = "";
    this.translationKeyword = "";
    this.status = true;
    this.isUpdate = false;
    this.description = "";
    this.gridContainerRef?.clear();
    this.loadFromModule();
  }

  cancel() {
    this.formContainerRef?.clear();
    this.loadGridModule();
    this.toolbarContainerRef?.clear();
    this.loadToolBarModule();
  }

  addRow(formvalue: any) {
    if (!this.isUpdate) {
      // formvalue.value['subCategoryImageUrl'] = this.subCategoryImageUrl;
      formvalue.value['lastUpdate']= moment(new Date()).format();
      this.httpService.addNewSubCategory(formvalue.value).then(res => {
        console.log(res);
        // this.subCategoryImageUrl = [];
        this.fileToUpload = null;
        this.httpService.openSnankBar('Successfully added SubCategory Record')
        this.formContainerRef?.clear();
        this.toolbarContainerRef?.clear();
        this.loadToolBarModule();
        this.loadGridModule();
      }).catch(error => {
        console.log(error);
        this.httpService.openSnankBar('Something went wrong!')
      })
    } else {
      // formvalue.value['subCategoryImageUrl'] = this.subCategoryImageUrl;
      formvalue.value['lastUpdate']= moment(new Date()).format();
      this.httpService.updateSubCategory(this.recordId, formvalue.value)
      this.httpService.openSnankBar('Successfully updated Sub Category Record');
      this.formContainerRef?.clear();
      this.toolbarContainerRef?.clear();
      this.loadToolBarModule();
      // this.subCategoryImageUrl = [];
      this.fileToUpload = null;
      this.loadGridModule();
    }
  }

  getListOfSubCategory() {
    this.httpService.getSubCategory().subscribe(data => {
      this.subCategoryList =  data;
    })
  }

  getAllCategory() {
    this.httpService.getCategory().subscribe(data => {
      this.categoryDataList= data;
      console.log(this.categoryDataList)
    })
  }


  getEditRowValue(value: any) {
    this.isUpdate = true;
    this.gridContainerRef?.clear();
    this.categoryName = value.item.categoryName;
    this.subCategoryName = value.item.subCategoryName;
    this.recordId = value.item.id
    this.translationKeyword = value.item.translationKeyword;
    this.loadFromModule();
  }

  deleteSubCategory(value: any) {
    if(confirm("Are you sure to delete "+value.item.subCategoryName)) {
    this.httpService.deleteSubCategory(value.item.id);
    this.getListOfSubCategory();
    }
  }

  refreshGrid() {
    this.loadGridModule();
  }

  handleFileInput(files: FileList) {
    this.fileToUpload = files;
  }


 //Upload Image Function returns a promise  
 async uploadImageAsPromise(imageFile: File) {
   return new Promise( (resolve, reject) => {
    const randomId = Math.random().toString(36).substring(2);
        const filePath = `subCategory/subCategory_${randomId}`;
        this.ref = this.storage.ref(randomId);
        this.task =  this.storage.upload(filePath, imageFile);
        this.uploadProgress = this.task.percentageChanges();
        const fileRef = this.storage.ref(filePath);

     this.task.on(
       "state_changed",
       function progress(snapshot: any) {
         const percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
       },

       function error(err: any) {
         reject(err);
       },

       async () => {
         //The getDownloadURL returns a promise and it is resolved to get the image url.
         const imageURL = await this.task.snapshot.ref.getDownloadURL();
         resolve(imageURL);
       }
     );
   });
 }
 
}