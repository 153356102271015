import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http'
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { LoginComponent } from '../component/login/login.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AngularFireAuth } from 'angularfire2/auth';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from 'angularfire2/storage';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
}


@Injectable({
  providedIn: 'root'
})
export class FrameWorkService {
  user: any;
  private basePath = '/calander';
  results$ = new Subject<any>();
  constructor(private http: HttpClient,
    private fireAuth: AngularFireAuth,
    private _snackBar: MatSnackBar,
    private fireStore: AngularFirestore,
    private storage: AngularFireStorage,
    private router: Router) {
    this.user = fireAuth.authState;
  }

  async authenticate(email: string, password: string) {
    await this.fireAuth
      .auth
      .signInWithEmailAndPassword(email, password).then((resp: any) => {
        console.log('Nice, it worked!', resp);
        this.openSnankBar('Suceessfully login with Digital Gujarat');
        this.extractData(resp.user.ya, resp.user.$b.email)
        this.router.navigateByUrl('category');
      }).catch(err => {
        this.openErrorSnakBar('Username or Password may be wrong!')
        console.log('Something went wrong:', err.message);
      });
  }

  logout() {
    this.fireAuth
      .auth
      .signOut();
  }


  // Authors
  getAllAuthors(pageNumber: number, pageSize: number): Observable<any> {
    return this.http.get(`get_all_author?page=${pageNumber}&PageSize=${pageSize}`);
  }


  openSnankBar(msg: string) {
    this._snackBar.open(msg, 'Close');
  }

  openErrorSnakBar(msg: string) {
    this._snackBar.open(msg, 'Error');
  }

  closeSnankBar() {
    this._snackBar.dismiss();
  }


  extractData(token: string, email: string) {
    sessionStorage.setItem(
      'digigujarat-token', token,
    );
    sessionStorage.setItem(
      'username', email
    )
  }

  fetchData(res: any) {
    if (res && res.status === true) {
      return res;
    } else {
      console.log('something went wrong');
      return res;
    }
  }

  public isAuthenticated(): boolean {
    let token: any = sessionStorage.getItem('digigujarat-token');
    if (token) {
      const helper = new JwtHelperService();
      if (helper.isTokenExpired(token) === true) {
        sessionStorage.removeItem('digigujarat-token');
        return false;
      }
      return true;
    } else {
      return false;
    }
  }

  handleError(error: HttpErrorResponse) {
    console.log(error);
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an ErrorObservable with a user-facing error message
    return throwError('Something went wrong');
  }

  // category crud start
  createNewCategory(payload: any) {
    console.log(payload);
    return this.fireStore.collection('category').add(payload);
  }


  getCategory(): Observable<any> {
    return this.fireStore.collection('category').snapshotChanges().pipe(
      map((snaps) =>
        snaps.map((snap) => {
          console.log(snap.payload);
          return {
            id: snap.payload.doc.id,
            ...(snap.payload.doc.data() as {}),
          }
        }),
      ),
    );
  }

  getSubCategoryById(categoryVal: string) {
    // this.results$.next([]);
    const studentsRef = this.fireStore.collection('subCategory').ref;
    const fnameQuery = studentsRef.where('categoryName', '==', categoryVal);
    var arr:any = [];
    fnameQuery.get().then(querySnapshot => {
      querySnapshot.forEach(documentSnapshot => {
        let num = 0;
        num ++;
        const data = documentSnapshot.data();
        arr.push(data);
        console.log(arr);
      })
      // this.results$.next(arr);
    });
    return arr;
  }
  updateCategory(id: string, payload: any) {
    this.fireStore.doc('category/' + id).update(payload)
  }

  deleteCategory(id: string) {
    this.fireStore.doc('category/' + id).delete();
  }

  // category crud end

  //calender  crud start
  uploadCalenderImage(file: any) {
    this.storage.upload('/', +Math.random() + file, file)
  }

  addNewCalender(payload: any) {
    return this.fireStore.collection('calender').add(payload);
  }

  updateCalender(id: string, payload: any) {
    this.fireStore.doc('calender/' + id).update(payload)
  }


  deleteCalender(id: string) {
    this.fireStore.doc('calender/' + id).delete();
  }

  getCalender(): Observable<any> {
    return this.fireStore.collection('calender').snapshotChanges().pipe(
      map((snaps) =>
        snaps.map((snap) => {
          return {
            id: snap.payload.doc.id,
            ...(snap.payload.doc.data() as {}),
          }
        })
      ),
    );
  }
  // calender crud end

  createNewMappingLanguage(payload: any) {
    console.log(payload);
    return this.fireStore.collection('cultureMapping').add(payload);
  }


  getMappingLanguageList(): Observable<any> {
    return this.fireStore.collection('cultureMapping').snapshotChanges().pipe(
      map((snaps) =>
        snaps.map((snap) => {
          return {
            id: snap.payload.doc.id,
            ...(snap.payload.doc.data() as {}),
          }
        })
      ),
    );
  }

  updateMappingLanguage(id: string, payload: any) {
    this.fireStore.doc('cultureMapping/' + id).update(payload)
  }

  deleteMappingLanguage(id: string) {
    this.fireStore.doc('cultureMapping/' + id).delete();
  }


  // SubCategory START CRUD

  uploadSubCategoryImage(file: any) {
    this.storage.upload('/', +Math.random() + file, file)
  }

  addNewSubCategory(payload: any) {
    return this.fireStore.collection('subCategory').add(payload);
  }

  updateSubCategory(id: string, payload: any) {
    this.fireStore.doc('subCategory/' + id).update(payload)
  }


  deleteSubCategory(id: string) {
    this.fireStore.doc('subCategory/' + id).delete();
  }

  getSubCategory(): Observable<any> {
    return this.fireStore.collection('subCategory').snapshotChanges().pipe(
      map((snaps) =>
        snaps.map((snap) => {
          return {
            id: snap.payload.doc.id,
            ...(snap.payload.doc.data() as {}),
          }
        }),
      ),
    );
  }

  // MetaData 
  uploadSubCategoryMetaDataImage(file: any) {
    this.storage.upload('/', +Math.random() + file, file)
  }

  addNewMetaData(payload: any) {
    return this.fireStore.collection('MetaData').add(payload);
  }

  updateMetaData(id: string, payload: any) {
    this.fireStore.doc('MetaData/' + id).update(payload)
  }


  deleteMetaData(id: string) {
    this.fireStore.doc('MetaData/' + id).delete();
  }

  getMetaData(): Observable<any> {
    return this.fireStore.collection('MetaData', ref => ref
      .limit(5)).snapshotChanges().pipe(
        map((snaps) =>
          snaps.map((snap) => {
            return {
              id: snap.payload.doc.id,
              ...(snap.payload.doc.data() as {}),
            }
          }),
        ),
      );
  }

  deleteCalanderImage(downloadUrl: string) {
    const storageRef = this.storage.ref(this.basePath);
    storageRef.child(downloadUrl).delete();
    return this.storage.storage.refFromURL(downloadUrl).delete();
  }

  deleteMetaDataImage(downloadUrl: string) {
    const storageRef = this.storage.ref('/MetaData');
    storageRef.child(downloadUrl).delete();
    return this.storage.storage.refFromURL(downloadUrl).delete();
  }


}
