import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-common-header',
  templateUrl: './common-header.component.html',
  styleUrls: ['./common-header.component.scss']
})
export class CommonHeaderComponent implements OnInit  {

  usernameChar : string = '';
  username : any;
  userEmail : any = '';
  isProfileCard: boolean = true;
  color: string = '#';
  @Input() breadCrumbObject = [];
  @Input() PageTitle  = "";
  breadCrumbList: any;
  constructor(private router: Router) { }
  ngOnInit(): void {
    this.breadCrumbList = this.breadCrumbObject;
    this.breadCrumbObject = this.breadCrumbObject;
    this.username = sessionStorage.getItem('username')?.toLocaleUpperCase();
    this.userEmail = sessionStorage.getItem('email')?.toLocaleUpperCase();
    let str = sessionStorage.getItem('username');
    this.usernameChar = str?.charAt(0) ? str?.charAt(0) : '';
    this.getRandomRolor();
  }


  getRandomRolor() {
    var letters = '012345'.split('');
    this.color = '#';        
    this.color += letters[Math.round(Math.random() * 5)];
    letters = '0123456789ABCDEF'.split('');
    for (var i = 0; i < 5; i++) {
        this.color += letters[Math.round(Math.random() * 15)];
    }
     return this.color;
} 

  logout() {
    sessionStorage.removeItem('digigujarat-token');
    sessionStorage.removeItem('username');
    sessionStorage.removeItem('email');
    this.router.navigateByUrl('/dg-login');
  }

  openCard () {
    this.isProfileCard =! this.isProfileCard;
  }

   

}
