// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
   apiUrl: "http://localhost:8000/api/",
   firebaseConfig : {
    apiKey: "AIzaSyAhzA7rDSbsz8ikKGpM_CKqWgvdns_vSpw",
    authDomain: "digitalgujarati-c7679.firebaseapp.com",
    databaseURL: "https://digitalgujarati-c7679-default-rtdb.firebaseio.com",
    projectId: "digitalgujarati-c7679",
    storageBucket: "digitalgujarati-c7679.appspot.com",
    messagingSenderId: "356331632818",
    appId: "1:356331632818:web:b79b61d26ac7a47a2471ab",
    measurementId: "G-9LFD9M92WF"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
