import { CurrencyPipe, DecimalPipe, PercentPipe } from '@angular/common';
import { ChangeDetectorRef, Component, ElementRef, OnInit, TemplateRef, VERSION, ViewChild, ViewContainerRef } from '@angular/core';
import { FormBuilder, FormGroup, NgForm } from '@angular/forms';
import { Sort } from '@angular/material/sort';
import { AngularFireStorage  } from 'angularfire2/storage';
import * as moment from 'moment';
import { finalize } from 'rxjs/operators';
import { FrameWorkService } from 'src/app/shared/frame-work.service';


@Component({
  selector: 'app-calender',
  templateUrl: './calender.component.html',
  styleUrls: ['./calender.component.scss']
})
export class CalenderComponent implements OnInit {
  @ViewChild('gridContainer', { read: ViewContainerRef, static: true }) gridContainerRef: ViewContainerRef | undefined;
  @ViewChild('gridTemplate', { static: true }) gridTemplateRef: TemplateRef<any> | any;
  @ViewChild('formContainer', { read: ViewContainerRef, static: true }) formContainerRef: ViewContainerRef | undefined;
  @ViewChild('formTemplate', { static: true }) formTemplateRef: TemplateRef<any> | any;
  @ViewChild('toolbarContainer', { read: ViewContainerRef, static: true }) toolbarContainerRef: ViewContainerRef | undefined;
  @ViewChild('toolbarTemplate', { static: true }) toolbarTemplateRef: TemplateRef<any> | any;
  data: { title: string; link: string; cssClass: string; }[] | any;
  pageTitle: string = "Calender Management";
  CalenderForm: NgForm | undefined;
  authorsData = [];
  calenderColumns = [{ title: 'Month Name', key: 'month', type: 'string' },
  { title: 'Year', key: 'year', type: 'string' },
  { title: 'Month Image Url', key: 'monthImageUrl', type: 'img' }];
  totalRecords: number | undefined;
  perPage: number = 5;
  calenderList: any;
  month: string = "";
  year: string = "2021"
  preferenceOrder: string = ""
  isUpdate: boolean | undefined = false;
  recordId: any;
  version = VERSION;
  base64File: any
  filename: any;
  fileToUpload: File | null | undefined;
  task: any;
  ref: any;
  uploadProgress: any;
  downloadURL: any;
  downloadUrl$: any;
  monthImageUrl: any;
  listOfMonth = ['January', 'Febuary', 'March', 'April', 'May', 'June', 'July', 'August',
    'September', 'Octomber', 'November', 'December'];
  listOfYear: any = [];
  fileUrl: any;


  constructor(private fb: FormBuilder,
    private storage: AngularFireStorage,
    private httpService: FrameWorkService,
    private cdRef: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.setHeaderConfig();
    this.loadToolBarModule();
    this.loadGridModule();
    this.getListOfCalender();
  }

  loadGridModule() {
    this.gridContainerRef?.createEmbeddedView(this.gridTemplateRef);
  }

  loadFromModule() {
    this.formContainerRef?.createEmbeddedView(this.formTemplateRef);
  }

  loadToolBarModule() {
    this.toolbarContainerRef?.createEmbeddedView(this.toolbarTemplateRef);
  }

  setHeaderConfig() {
    this.data = []
  }

  createForm() {
    let currentYear = moment().year();
    this.listOfYear = [];
    for(let i = 0; i<4; i++) {
      this.listOfYear.push(currentYear)
      currentYear++;
    }
    this.month = "";
    this.recordId = "";
    this.year = "";
    this.monthImageUrl = "";
    this.isUpdate = false;
    this.gridContainerRef?.clear();
    this.loadFromModule();

  }

  cancel() {
    this.formContainerRef?.clear();
    this.loadGridModule();
    this.toolbarContainerRef?.clear();
    this.loadToolBarModule();
  }


  addRow(formvalue: any) {
    if (!this.isUpdate) {
      formvalue.value['monthImageUrl'] = this.monthImageUrl;
      this.httpService.addNewCalender(formvalue.value).then(res => {
        this.monthImageUrl = "";
        this.fileToUpload = null;
        this.httpService.openSnankBar('Successfully added Calender Record')
        this.formContainerRef?.clear();
        this.loadGridModule();
        this.toolbarContainerRef?.clear();
        this.loadToolBarModule();
      }).catch(error => {
        this.httpService.openSnankBar('Something went wrong!')
      })
    } else {
      formvalue.value['monthImageUrl'] = this.monthImageUrl;
      this.httpService.updateCalender(this.recordId, formvalue.value)
      this.httpService.openSnankBar('Successfully updated Calender Record');
      this.formContainerRef?.clear();
      this.monthImageUrl = "";
      this.fileToUpload = null;
      this.loadGridModule();
    }
  }

  getListOfCalender() {
    this.httpService.getCalender().subscribe(data => {
      console.log(data);
      this.calenderList = data
    })
  }


  getEditRowValue(value: any) {
    let currentYear = moment().year();
    this.listOfYear = [];
    for(let i = 0; i<4; i++) {
      this.listOfYear.push(currentYear)
      currentYear++;
    }
    this.isUpdate = true;
    this.gridContainerRef?.clear();
    this.month = value.item.month;
    this.year = value.item.year
    this.recordId = value.item.id
    this.monthImageUrl = value.item.monthImageUrl;
    this.fileUrl = value.item.monthImageUrl;
    this.loadFromModule();
  }

  deleteCategoryRecord(value: any) {
    if(confirm("Are you sure to delete "+value.item.month)) {
    this.httpService.deleteCalanderImage(value.item.monthImageUrl);
    this.httpService.deleteCalender(value.item.id);
    this.getListOfCalender();
    }
  }

  refreshGrid() {
    this.loadGridModule();
  }

  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);
  }

  uploadImage() {
    if (this.fileToUpload) {
      if(this.fileUrl) {
        this.httpService.deleteCalanderImage(this.fileUrl);
      }
      const randomId = Math.random().toString(36).substring(2);
      const filePath = `calander/month_${randomId}`;
      this.ref = this.storage.ref(randomId);
      this.httpService.deleteCalender
      this.task = this.storage.upload(filePath, this.fileToUpload);
      this.uploadProgress = this.task.percentageChanges();
      const fileRef = this.storage.ref(filePath);
      this.task.snapshotChanges().pipe(
        finalize(() => {
          this.downloadUrl$ = fileRef.getDownloadURL().subscribe(res => {
            this.monthImageUrl = res;
            this.fileToUpload = null;
          });
        })
      ).subscribe();
    }
  }

  removeImage() {

  }

}

export interface calender {
  monthName: string,
  year: number,
  monthImageUrl: string
}
