import { ChangeDetectorRef, Component, Input, NgZone, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-left-nav',
  templateUrl: './left-nav.component.html',
  styleUrls: ['./left-nav.component.scss']
})
export class LeftNavComponent implements OnInit {
  isSidePanelOpen: boolean = true;
  isChild: boolean = false;
  selectedIndex:number = 0;
  selectChildIndex:number = -1;
  menuItem = [
  {'label':'Category Master', 'link':'/category', 'isHasChild':[], 'icons':'ds', 'isActive': false},
  {'label':'Sub Category Master', 'link':'/sub_category', 'isHasChild':[], 'icons':'ds', 'isActive': false},
  {'label':'Calander Master', 'link':'/calender', 'isHasChild':[], 'icons':'ds', 'isActive': false},
  {'label':'List of Value', 'link':'/lov', 'isHasChild':[], 'icons':'ds', 'isActive': false},
  {'label':'MetaData', 'link':'/metadata', 'isHasChild':[], 'icons':'', 'isActive': false}
  ];

  constructor(private cdref:ChangeDetectorRef , private router: Router , private zone: NgZone) { }
  ngOnInit(): void {
  }

  toggle() {
    this.isSidePanelOpen = !this.isSidePanelOpen;
    this.cdref.detectChanges();
  }

  getSubMenu() {

  }
   setIndex(index: number , item: any) {
      this.router.navigateByUrl(item.link);
      this.selectedIndex = index;
   }

   getRoute(item : any) {
    this.menuItem.forEach((c)=>  {
      c.isActive == false;
    }
    );
    item.isActive = true;
    }

   setChildIndex(index:number) {
    this.selectChildIndex = index;
   }
}

export interface menuItem  {
  label:string;
  link:string,
  isHasChild:any[],
  icons:string,
}

