import { CurrencyPipe, DecimalPipe, PercentPipe } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { FormBuilder, FormGroup, NgForm } from '@angular/forms';
import { Sort } from '@angular/material/sort';
import { FrameWorkService } from 'src/app/shared/frame-work.service';

export interface Order {
  id: number;
  description: string;
  amount: number;
  price: number;
  discount: number;
}
@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss']
})
export class CategoryComponent implements OnInit {
  @ViewChild('gridContainer', { read: ViewContainerRef, static: true }) gridContainerRef: ViewContainerRef | undefined;
  @ViewChild('gridTemplate', { static: true }) gridTemplateRef: TemplateRef<any> | any;
  @ViewChild('toolbarContainer', { read: ViewContainerRef, static: true }) toolbarContainerRef: ViewContainerRef | undefined;
  @ViewChild('toolbarTemplate', { static: true }) toolbarTemplateRef: TemplateRef<any> | any;
  @ViewChild('formContainer', { read: ViewContainerRef, static: true }) formContainerRef: ViewContainerRef | undefined;
  @ViewChild('formTemplate', { static: true }) formTemplateRef: TemplateRef<any> | any;
  data: { title: string; link: string; cssClass: string; }[] | any;
  pageTitle: string = "Category Management";
  CategoryForm: NgForm | undefined;
  authorsData = [];
  categoryColumns = [{ title: 'Category Name', key: 'categoryname', type : 'string' },
  { title: 'Translation keyword', key: 'translationKeyword', type : 'string' }, 
  { title: 'PrefrenceOrder', key: 'preferenceOrder' , type : 'string' }, 
  { title: 'Status', key: 'status' , type : 'string' }];
  totalRecords: number | undefined;
  perPage: number = 5;
  categoryList: any;
  categoryname: string = "";
  status = true;
  preferenceOrder: string = ""
  isUpdate: boolean | undefined = false;
  recordId: any;
  translationKeyword: string= "";


  constructor(private fb: FormBuilder, private httpService: FrameWorkService,
    private cdRef: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.setHeaderConfig();
    this.loadToolBarModule();
    this.loadGridModule();
    this.getListOfCategory();

  }

  loadGridModule() {
    this.gridContainerRef?.createEmbeddedView(this.gridTemplateRef);
  }

  loadFromModule() {
    this.formContainerRef?.createEmbeddedView(this.formTemplateRef);
  }

  loadToolBarModule() {
    this.toolbarContainerRef?.createEmbeddedView(this.toolbarTemplateRef);
  }

  setHeaderConfig() {
    this.data = []
  }

  createForm() {
    this.categoryname = "";
    this.recordId = "";
    this.status = true;
    this.preferenceOrder = "";
    this.translationKeyword= ""
    this.isUpdate = false;
    this.gridContainerRef?.clear();
    this.loadFromModule();
  }

  cancel() {
    this.formContainerRef?.clear();
    this.loadGridModule();
    this.toolbarContainerRef?.clear();
    this.loadToolBarModule();
  }

  addRow(formvalue : any) {
    if(!this.isUpdate) {
      this.httpService.createNewCategory(formvalue.value).then(res => {
        console.log(res);
        this.httpService.openSnankBar('Successfully added Category Record')
        this.formContainerRef?.clear();
        this.loadGridModule();
        this.toolbarContainerRef?.clear()
        this.loadToolBarModule();
      }).catch(error => {
        console.log(error);
        this.httpService.openSnankBar('Something went wrong!')
      })
    } else {
      this.httpService.updateCategory(this.recordId, formvalue.value)
      this.httpService.openSnankBar('Successfully updated Category Record');
      this.formContainerRef?.clear();
      this.loadGridModule();
    }
  }

  getListOfCategory() {
    this.httpService.getCategory().subscribe(data => {
      console.log(data);
      this.categoryList = data
    })
  }


  getEditRowValue(value: any) {
    this.isUpdate = true;
    this.gridContainerRef?.clear();
    this.categoryname = value.item.categoryname;
    this.status = value.item.status == "true" ? true : false;
    this.recordId = value.item.id;
    this.translationKeyword = value.item.translationKeyword;
    this.preferenceOrder = value.item.preferenceOrder;
    this.loadFromModule();
  }

  deleteCategoryRecord(val:any) {
    if(confirm("Are you sure to delete "+val.item.categoryname)) {
      console.log("Implement delete functionality here");
    this.httpService.deleteCategory(val.item.id);
    this.getListOfCategory();
  }
  }

  checkCategoryIsUsed(val: any) {
    let data:any = [];
     data =  this.httpService.getSubCategoryById(val.item.categoryname);
   console.log(data);
   setTimeout(() => {
     console.log('wait')
   },500);
   if(data.length == 0) {
   
 } else {
   this.httpService.openSnankBar("There is Dependencies for this Category");
 }
  }

  refreshGrid() {
    this.loadGridModule();
  }

}

export interface Category {
  categoryName: string,
  preferenceOrder:number,
  isActive: boolean 
}
