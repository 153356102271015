import { ChangeDetectorRef, Component, ElementRef, OnInit, TemplateRef, VERSION, ViewChild, ViewContainerRef } from '@angular/core';
import { FormBuilder, FormGroup, NgForm } from '@angular/forms';
import { AngularFireStorage  } from 'angularfire2/storage';
import * as moment from 'moment';
import { finalize } from 'rxjs/operators';
import { FrameWorkService } from 'src/app/shared/frame-work.service';


@Component({
  selector: 'app-meta-data',
  templateUrl: './meta-data.component.html',
  styleUrls: ['./meta-data.component.scss']
})
export class MetaDataComponent implements OnInit {
  @ViewChild('gridContainer', { read: ViewContainerRef, static: true }) gridContainerRef: ViewContainerRef | undefined;
  @ViewChild('gridTemplate', { static: true }) gridTemplateRef: TemplateRef<any> | any;
  @ViewChild('formContainer', { read: ViewContainerRef, static: true }) formContainerRef: ViewContainerRef | undefined;
  @ViewChild('formTemplate', { static: true }) formTemplateRef: TemplateRef<any> | any;
  @ViewChild('toolbarContainer', { read: ViewContainerRef, static: true }) toolbarContainerRef: ViewContainerRef | undefined;
  @ViewChild('toolbarTemplate', { static: true }) toolbarTemplateRef: TemplateRef<any> | any;
  data: { title: string; link: string; cssClass: string; }[] | any;
  pageTitle: string = "MetaData Management";
  MetaDataForm: NgForm | undefined;
  authorsData = [];
  calenderColumns = [{ title: 'Category Name', key: 'category', type: 'string' },
  { title: 'Sub Category', key: 'subCategory', type: 'string' },
  { title: 'MetaData', key: 'metaDataImageUrl', type: 'img' },
  { title: 'Last Update', key: 'lastUpdate', type: 'string' },
  { title: 'Status', key: 'status', type: 'string' }];
  totalRecords: number | undefined;
  perPage: number = 5;
  MetaDataList: any;
  status: boolean = true;
  CategoryList = [];
  SubCategoryList = [];
  subCategory = "";
  category: string = "";
  year: string = "2021"
  preferenceOrder: string = ""
  isUpdate: boolean | undefined = false;
  recordId: any;
  version = VERSION;
  base64File: any
  filename: any;
  fileToUpload: File | null | undefined;
  task: any;
  ref: any;
  uploadProgress: any;
  downloadURL: any;
  downloadUrl$: any;
  metaDataImageUrl: any;
  listOfCategory = [];
  fileUrl: any;

  constructor(
    private storage: AngularFireStorage,
    private httpService: FrameWorkService,
    private cdRef: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.setHeaderConfig();
    this.loadToolBarModule();
    this.loadGridModule();
    this.getListOfMedata();
  }

  loadGridModule() {
    this.gridContainerRef?.createEmbeddedView(this.gridTemplateRef);
  }

  loadFromModule() {
    this.formContainerRef?.createEmbeddedView(this.formTemplateRef);
  }

  setHeaderConfig() {
    this.data = []
  }

  createForm() {
    this.category = "";
    this.subCategory = "";
    this.recordId = "";
    this.year = "";
    this.isUpdate = false;
    this.gridContainerRef?.clear();
    this.metaDataImageUrl = "";
    this.getCategory();
    this.getSubCategory();
    this.loadFromModule();
  }

  getCategory() {
    this.httpService.getCategory().subscribe(resp => {
      this.CategoryList = resp
    })
  }

  getSubCategory() {
    this.httpService.getSubCategory().subscribe(resp => {
      this.SubCategoryList = resp
    })
  }

  cancel() {
    this.formContainerRef?.clear();
    this.loadGridModule();
    this.toolbarContainerRef?.clear();
    this.loadToolBarModule();
  }

  addRow(formvalue: any) {
    if (!this.isUpdate) {
      formvalue.value['metaDataImageUrl'] = this.metaDataImageUrl;
      formvalue.value['lastUpdate']= moment(new Date()).format();
      this.httpService.addNewMetaData(formvalue.value).then(res => {
        this.metaDataImageUrl = "";
        this.fileToUpload = null;
        this.httpService.openSnankBar('Successfully added Metadata Record')
        this.formContainerRef?.clear();
        this.loadGridModule();  
        this.toolbarContainerRef?.clear();
        this.loadToolBarModule();
      }).catch(error => {
        this.httpService.openSnankBar('Something went wrong!')
      })
    } else {
      formvalue.value['metaDataImageUrl'] = this.metaDataImageUrl;
      formvalue.value['lastUpdate']= moment(new Date()).format();
      this.httpService.updateMetaData(this.recordId, formvalue.value)
      this.httpService.openSnankBar('Successfully updated MetaData Record');
      this.formContainerRef?.clear();
      this.metaDataImageUrl = "";
      this.fileToUpload = null;
      this.loadGridModule();
    }
  }

  getListOfMedata() {
    this.httpService.getMetaData().subscribe(data => {
      this.MetaDataList = data
      this.MetaDataList.map((x: { [x: string]: moment.MomentInput; }) => {
        x['lastUpdate'] =  moment(x['lastUpdate']).fromNow()
      })
    })
  }

  loadToolBarModule() {
    this.toolbarContainerRef?.createEmbeddedView(this.toolbarTemplateRef);
  }

  getEditRowValue(value: any) {
    this.isUpdate = true;
    this.gridContainerRef?.clear();
    this.category = value.item.category;
    this.subCategory = value.item.subCategory
    this.recordId = value.item.id;
    this.status = value.item.status;
    this.metaDataImageUrl = value.item.metaDataImageUrl;
    this.fileUrl = value.item.metaDataImageUrl;
    this.getCategory();
    this.getSubCategory();
    this.loadFromModule();
  }

  deleteCategoryRecord(value: any) {
    if(confirm("Are you sure to delete "+value.item.id)) {
    this.httpService.deleteMetaDataImage(value.item.metaDataImageUrl);
    this.httpService.deleteMetaData(value.item.id);
    this.getListOfMedata();
    }
  }

  refreshGrid() {
    this.loadGridModule();
  }

  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);
  }

  uploadImage() {
    if (this.fileToUpload) {
      if(this.fileUrl) {
        this.httpService.deleteMetaDataImage(this.fileUrl);
      }
      const randomId = Math.random().toString(36).substring(2);
      const filePath = `MetaData/metaData_${randomId}`;
      this.ref = this.storage.ref(randomId);
      this.task = this.storage.upload(filePath, this.fileToUpload);
      this.uploadProgress = this.task.percentageChanges();
      const fileRef = this.storage.ref(filePath);
      this.task.snapshotChanges().pipe(
        finalize(() => {
          this.downloadUrl$ = fileRef.getDownloadURL().subscribe(res => {
            this.metaDataImageUrl = res;
            this.fileToUpload = null;
          });
        })
      ).subscribe();
    }
  }
}

